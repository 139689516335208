import React from 'react';

// Import Services
import paymentService from '../../../services/paymentService';

const PromotionCode = ({ updatePromoCode }) => {
  const [couponCode, setCouponCode] = React.useState('');
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    setError(null);
    if (couponCode) {
      setLoading(true);
      let couponDetail = await paymentService.checkCouponCode(couponCode);
      setLoading(false);
      if (couponDetail) {
        if (couponDetail.error) {
          setError(couponDetail.error);
        } else {
          updatePromoCode(couponDetail);
          return;
        }
      }
    }
    updatePromoCode({});
    return;
  };

  return (
    <div className="fullWidthContainer">
      <span className="OnePortionFlex">
        <input
          type="text"
          value={couponCode}
          onChange={(e) => {
            setCouponCode(e.target.value);
          }}
          placeholder="Promo Code"
        />
      </span>
      <button className="OnePortionFlex" disabled={loading} onClick={handleSubmit}>
        Apply
      </button>

      <div style={{ minHeight: '30px' }} className="fullWidthContainer errorText">
        {error ? error : ' '}
      </div>
    </div>
  );
};

export default PromotionCode;
