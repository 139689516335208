// Libraries
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { useAuth0 } from '@auth0/auth0-react';
import { FullStory, init } from '@fullstory/browser';
//import gtag from 'ga-gtag';

// Services
import userService from '../services/userService.js';
import paymentService from '../services/paymentService.js';

// Import Context
import { UserContext } from '../context/User';

// Import Components
import useIdleTimer from './useIdleTimer.js';

Modal.setAppElement('#root');

// Construct Component
const UserManager = ({ callRender }) => {
  const { user, setUser, updateUser, setUpdateUser } = useContext(UserContext);
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
  const [isIdleOpen, setIsIdleOpen] = useState(false);
  const lastActiveRef = useRef(new Date());
  const [hasSessionClosed, setHasSessionClosed] = useState(false);
  const site = window.location.origin;

  useIdleTimer(() => {
    if (!hasSessionClosed) {
      setIsIdleOpen(true);
      closeUserSession();
      setHasSessionClosed(true);
    }
  }, 30 * 60 * 1000);

  async function fetchToken() {
    const valid = (await userService.validateToken()) || (await refreshToken());
    return valid;
  }

  async function refreshToken() {
    try {
      const accessToken = await getAccessTokenSilently({ grant: 'refresh_token' });
      return await userService.validateToken(accessToken);
    } catch {
      return false;
    }
  }

  const closeUserSession = () => {
    /*
    gtag('event', 'user_logout', {
      user_id: user.id,
    });
    */
    if (localStorage.getItem('user')) {
      localStorage.removeItem('user');
    }
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      logout({ logoutParams: { returnTo: site } });
    } else {
      const redirectPath = '/';
      if (window.location.pathname !== redirectPath) {
        window.location.pathname = redirectPath;
      }
    }
  };

  const fullUserDataLoad = async () => {
    let newData = {};
    const userData = await userService.getUserData();
    if (userData) {
      let isStaff = await userService.validateStaffToken();
      isStaff = isStaff ? true : false;

      newData = {
        ...userData,
        isStaff: isStaff,
        cards: await paymentService.getAvailableCards(userData.id),
        invoiceDue: await paymentService.checkForPaymentsDue(userData.id),
      };
      setUser(newData);
    }
    return newData;
  };

  const handleUserUpdate = async (updateType) => {
    let update = updateType || updateUser;
    async function updateSubscription() {
      let newUserData = await fullUserDataLoad();
      setUpdateUser('');
    }

    async function updateBasic() {
      const userData = await userService.getUserData();
      if (userData && userData.id) {
        let updatedUser = { ...user, ...userData };
        setUser(updatedUser);
        setUpdateUser('');
      }
    }

    if (update === 'subscription') {
      updateSubscription();
    } else if (update === 'basic') {
      updateBasic();
    }
  };

  useEffect(() => {
    async function initializeUser() {
      const tokenValid = await fetchToken();
      let tempUser = localStorage.getItem('user');
      if (tokenValid && !user?.id) {
        console.log('initialize User');
        let userData = await fullUserDataLoad();
        window.usetifulTags = { userId: userData.id };
        init({ orgId: 'o-1STRV8-na1' });
        FullStory('setIdentity', { uid: userData.id, properties: userData });
        /*
        gtag('event', 'user_login', {
          user_id: userData.id,
        });
        */
      }
    }
    initializeUser();

    return () => {
      // Cleanup logic if needed
    };
  }, [user, setUser, isLoading]);

  useEffect(() => {
    if ((user && user.id) || !window.location.pathname.includes('/app/')) {
      callRender(true);
    }
  }, [user]);

  useEffect(() => {
    handleUserUpdate();
  }, [updateUser]);

  useEffect(() => {
    handleUserUpdate('basic');
  }, [window.location.pathname]);

  return <></>;
};

export default UserManager;
