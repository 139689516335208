// Construct Component
function Confirmation({ scriptId, nextStep }) {
  return (
    <div>
      <div className="modalHeader" id="successful_new_subscription">
        Congratulations!
      </div>
      <div className="modalContent">
        <div>
          You successfully activated your subscription. You can now access all of our advanced
          features. This includes
          <ul>
            <li>Generate shots for every scene for up to 3 scripts per month</li>
            <li>More Fields: Multi-Cam, etc.</li>
            <li>Flexible Export Options including CSV</li>
          </ul>
          <br />
          <br />
          {scriptId ? (
            <div>This script has been upgrade to a full featured script as well.</div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
