// Import Libraries
import React, { useState, useEffect } from 'react';

// Import Components
import RadioButton from '../../Elements/RadioButton.js';

// Import Services
import subscriptionService from '../../../services/subscriptionService';

const PlanList = ({ orderInfo, setOrderInfo }) => {
  const [bestValue, setBestValue] = useState('0.00');

  //let dueDate = new Date();
  //dueDate.setMonth(dueDate.getMonth() + 1);

  const changePricing = (hold, field, newPricing, plan) => {
    if (plan === undefined || plan === null || !plan) {
      plan = orderInfo.plans.find((plan) => plan.value === newPricing);
    }
    setOrderInfo({
      ...orderInfo,
      amountDue: plan.details['price'],
      includeTrial: plan.details['include_trial'],
      pricing: newPricing,
      selectedPlan: plan,
    });
  };

  useEffect(() => {
    async function setup() {
      let getPlans = await subscriptionService.fetchAvailablePlans();
      if (orderInfo.includeFree) {
        getPlans.unshift({
          label: 'Free',
          value: '0',
          details: {
            frequency: 'Free',
            price: '0.00',
            user_level: '',
          },
        });
      }
      getPlans = getPlans.slice().sort((a, b) => {
        const priceA = parseFloat(a.details.price);
        const priceB = parseFloat(b.details.price);
        return priceB - priceA;
      });
      getBestValue(getPlans);
    }

    async function getBestValue(getPlans) {
      if (getPlans && getPlans.length > 0) {
        let yearlyPlan = getPlans.find((plan) => plan.details['frequency'] === 'Yearly');
        if (!yearlyPlan) {
          setOrderInfo({ ...orderInfo, plans: getPlans });
          return;
        }
        changePricing(null, null, yearlyPlan.value, yearlyPlan);
        setOrderInfo({ ...orderInfo, plans: getPlans });
        let smallest =
          getPlans[getPlans.length - 1].label == 'Free'
            ? getPlans[getPlans.length - 2]
            : getPlans[getPlans.length - 1];
        let savings =
          parseFloat(yearlyPlan.details.price) - parseFloat(smallest.details.price * 12);
        savings = savings.toFixed(2);
        setBestValue(savings * -1);
      }
    }
    if (orderInfo.plans.length === 0) {
      setup();
    } else {
      getBestValue(orderInfo.plans);
    }
  }, []);

  return (
    <>
      {orderInfo.plans &&
        orderInfo.plans.length > 0 &&
        orderInfo.plans.map((plan, index) => (
          <div key={index.toString()}>
            <div className="FullWidthFlex Padded">
              <span className="OnePortionFlex MinSmall">
                <span className="bold">
                  <RadioButton
                    name="timing"
                    value={plan.value}
                    label={
                      plan.label +
                      ' ' +
                      (plan.details.user_level.charAt(0).toUpperCase() +
                        plan.details.user_level.slice(1))
                    }
                    selected={orderInfo.pricing === plan.value ? true : false}
                    saveUpdate={changePricing}
                  />
                </span>
                <div className="Padded" style={{ marginLeft: '10px' }}>
                  {`$${plan.details.price}`}
                  {plan.details['frequency'] === 'Yearly' && (
                    <span style={{ paddingLeft: '10px' }}>
                      {' '}
                      (
                      {(plan.details.price / 12).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                      })}
                      /month )
                    </span>
                  )}
                </div>
                {plan.details['include_trial'] ? (
                  <div className="Padded" style={{ marginLeft: '10px' }}>
                    {plan.details['include_trial'] ? 'Includes 1 month trial' : null}
                  </div>
                ) : null}
              </span>
              {plan.details['staff_only'] ? (
                <span
                  className="OnePortionFlex item bold"
                  style={{
                    fontSize: '14px',
                    backgroundColor: '#E35640',
                    color: 'White',
                    borderRadius: '10px',
                  }}
                >
                  STAFF ONLY
                </span>
              ) : plan.details['frequency'] === 'Yearly' ? (
                <span
                  className="OnePortionFlex item bold MinExSmall"
                  style={{
                    fontSize: '14px',
                    backgroundColor: '#E35640',
                    color: 'White',
                    borderRadius: '10px',
                    marginLeft: '10px',
                    alignContent: 'center',
                  }}
                >
                  Best Value: Save ${bestValue}
                </span>
              ) : null}
            </div>
            <div
              className="FullWidthFlex Padded"
              style={{
                display: 'block',
                marginLeft: '15px',
              }}
            ></div>
            <br />
          </div>
        ))}
    </>
  );
};

export default PlanList;
