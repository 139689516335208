// Import Libraries
import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';

// Import Components
import CheckoutForm from './CheckoutForm';
import StripeWrapper from './StripeWrapper';
import PlanList from '../Subscribe/planList';
import PromotionCode from './promotionCode';

// Import Services
import subService from '../../../services/subscriptionService';

// Context
import { UserContext } from '../../../context/User';
import SessionContext from '../../../context/Session';

// Setup Modal to Root
Modal.setAppElement('#root');

const ProcessSubCharge = ({ scriptId, nextStep, userDetails, orderInfo, setOrderInfo }) => {
  const { user, setUser } = useContext(UserContext);
  const [newCard, setNewCard] = useState(false);
  const [hasCards, setHasCards] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { isMobile } = useContext(SessionContext);

  let useUser = userDetails ? userDetails : user;

  const continueWithTrial = async () => {
    let submitted = await subService.createSubscription(useUser.id, 'trial', orderInfo, scriptId);
    if (submitted) {
      nextStep(4);
    }
  };

  const updatePromoCode = async (code) => {
    code = code || {};

    let discount = 0;
    if (code.type === 'percent') {
      discount = orderInfo.amountDue * (code.discount / 100);
    } else if (code.type === 'fixed') {
      discount = code.discount;
    }
    setOrderInfo({
      ...orderInfo,
      couponCode: code,
      discount: discount,
      amountDue: orderInfo.amountDue - discount,
    });
    return;
  };

  useEffect(() => {
    if (useUser && useUser.cards && useUser.cards.length > 0) {
      setHasCards(true);
    } else if (useUser) {
      setHasCards(false);
      setNewCard(true);
    }
    if (!orderInfo.selectedPlan || !orderInfo.selectedPlan.details) {
      nextStep(1);
    }
    setIsLoading(false);
  }, [useUser]);

  return (
    <>
      {' '}
      {orderInfo.selectedPlan ? (
        <>
          <div className="modalHeader bold fullWidthContainer">
            <span className="TwoPortionFlex">Process Payment</span>
            <span className="OnePortionFlex"></span>

            {useUser.id !== user.id &&
            orderInfo.selectedPlan.details &&
            orderInfo.selectedPlan.details['include_trial'] ? (
              <button
                className="TwoPortionFlex buttonClone MinSmall"
                onClick={() => {
                  continueWithTrial();
                }}
              >
                Skip Payment until after Trial
              </button>
            ) : null}
          </div>{' '}
          <div className="modalContent" style={{ margin: isMobile ? '-10px' : '0px' }}>
            <div className="fullWidthContainer Padded" style={{ paddingBottom: '30px' }}>
              <span
                className="OnePortionFlex Padded MinSmall"
                style={{ borderRight: isMobile ? '' : '1px solid #cccccc', alignSelf: 'baseline' }}
              >
                <div className="Padded" style={{ paddingBottom: '30px' }}>
                  <div className="bold">
                    Selected Plan:
                    <br />
                    <br />
                  </div>
                  <PlanList orderInfo={orderInfo} setOrderInfo={setOrderInfo} nextStep={nextStep} />

                  <div className="fullWidthContainer">
                    <div className="Padded fullWidthContainer">
                      <PromotionCode updatePromoCode={updatePromoCode} />
                    </div>
                    <div className="Padded fullWidthContainer">
                      <span className="bold OnePortionFlex">Renews</span>
                      <span className="OnePortionFlex" style={{ textAlign: 'right' }}>
                        {orderInfo.selectedPlan.label}
                      </span>
                    </div>

                    {orderInfo.discount ? (
                      <div className="Padded fullWidthContainer">
                        <span className="bold OnePortionFlex">Subtotal:</span>
                        <span className="OnePortionFlex" style={{ textAlign: 'right' }}>
                          {orderInfo.selectedPlan.details.price.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    ) : null}
                    {orderInfo.discount ? (
                      <div className="Padded fullWidthContainer">
                        <span className="bold OnePortionFlex">Discount:</span>
                        <span className="OnePortionFlex" style={{ textAlign: 'right' }}>
                          {orderInfo.discount.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    ) : null}

                    <div className="Padded fullWidthContainer">
                      <span className="bold OnePortionFlex">Total</span>
                      <span className="OnePortionFlex" style={{ textAlign: 'right' }}>
                        {orderInfo.amountDue.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                        })}
                      </span>
                    </div>

                    <div className="fullWidthContainer">&nbsp;</div>

                    {orderInfo.includeTrial ? (
                      <div className="Padded fullWidthContainer">
                        <span className="bold OnePortionFlex">Due On</span>
                        <span className="OnePortionFlex" style={{ textAlign: 'right' }}>
                          {(orderInfo.dueDate
                            ? new Date(orderInfo.dueDate)
                            : new Date()
                          ).toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </span>

              {!isLoading && (
                <div
                  className="DeepPadded OnePortionFlex"
                  style={{ minWidth: '250px', paddingBottom: '30px' }}
                >
                  {hasCards && !newCard ? (
                    <CheckoutForm
                      setNewCard={setNewCard}
                      scriptId={scriptId}
                      nextStep={nextStep}
                      orderInfo={orderInfo}
                    />
                  ) : (
                    <StripeWrapper
                      hasCards={hasCards ? true : false}
                      setNewCard={setNewCard}
                      scriptId={scriptId}
                      nextStep={nextStep}
                      orderInfo={orderInfo}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProcessSubCharge;
