// Import Libraries
import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';

// Import Components
import Icon from '../../Elements/Icon.js';
import PlanList from './planList';

// Import Services
import SessionContext from '../../../context/Session';

const SelectPlan = ({
  featureMsg,
  includeFree,
  nextStep,
  currentSubscription,
  orderInfo,
  setOrderInfo,
}) => {
  const { isMobile } = useContext(SessionContext);

  const onSubscribe = async () => {
    nextStep(2, orderInfo);
  };

  return (
    <>
      <div className="modalHeader bold" style={{ minHeight: '30px' }}>
        <div style={{ marginRight: '2%', float: 'left', width: '10%' }}>
          <Icon
            icon="Camera"
            color="Gold"
            customClass="proImg"
            size="20"
            customStyle={{ marginTop: '-5px' }}
          />
        </div>
        <div style={{ float: 'left', width: '85%' }}>
          {includeFree ? 'Change your Plan' : 'Upgrade to use this pro feature'}
        </div>
      </div>

      <div className="modalContent" style={{ margin: isMobile ? '-10px' : '0px' }}>
        <div className="FullWidthFlex Padded" style={{ paddingBottom: '30px' }}>
          <span
            className="OnePortionFlex Padded MinSmall"
            style={{ borderRight: isMobile ? '' : '1px solid #cccccc' }}
          >
            {featureMsg ? (
              <div className="headline">
                {`${featureMsg}`}
                {isMobile ? null : (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </div>
            ) : null}
            <PlanList orderInfo={orderInfo} setOrderInfo={setOrderInfo} nextStep={nextStep} />

            {isMobile ? null : (
              <>
                <br />
                <br />
              </>
            )}
            {orderInfo.includeTrial ? (
              <div className="FullWidthFlex Padded" style={{ paddingRight: '20px' }}>
                <span className="ThreePortionFlex">
                  Due on{' '}
                  {new Date(orderInfo.dueDate).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </span>
                <span className="ThreePortionFlex" style={{ textAlign: 'right' }}>
                  {parseFloat(orderInfo.amountDue).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </span>
              </div>
            ) : null}
          </span>
          <span className="OnePortionFlex Padded MinSmall">
            <div className="label headline">&nbsp;You get more with Pro</div>
            <div>
              <ul>
                <li>Generate shots for every scene</li>
                <li>Generate shots for the entire script in one click (coming soon)</li>
                <li>More Fields: Multi-Cam, etc.</li>
                <li>Flexible Export Options including CSV</li>
                <li>Upload Images and include them in PDF Exports</li>
              </ul>
            </div>
          </span>
        </div>

        <div className="FullWidthFlex Padded">
          {currentSubscription && currentSubscription.plan !== 'Free' ? (
            <button
              className="TwoPortionFlex linkText orangeText MinSmall"
              style={{ marginLeft: '30px', marginRight: '30px', marginTop: '20px' }}
              onClick={() => nextStep(3)}
            >
              Cancel my subscription
            </button>
          ) : (
            <span className="OnePortionFlex MinExSmall"></span>
          )}
          {isMobile ? null : <span className="OnePortionFlex"></span>}
          <button
            className="TwoPortionFlex buttonClone MinSmall"
            style={{ marginLeft: '30px', marginRight: '30px', marginTop: '20px' }}
            onClick={() => onSubscribe()}
            id="selectPlanNext"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectPlan;
