import React, { useEffect, useState, useContext } from 'react';

// Import Components
import LoadingSpinner from '../../Elements/LoadingSpinner';

// Context
import { UserContext } from '../../../context/User';
import paymentService from '../../../services/paymentService';
import subService from '../../../services/subscriptionService';

const CheckoutForm = ({ setNewCard, scriptId, invoice, nextStep, orderInfo }) => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);
    setIsLoading(true);

    let card = document.getElementById('card-select').value;
    let submitted = null;
    if (
      card &&
      orderInfo.selectedPlan &&
      orderInfo.selectedPlan.details &&
      orderInfo.selectedPlan.details.ext_product
    ) {
      //let product = orderInfo.selectedPlan.details.ext_product;
      submitted = await subService.createSubscription(user.id, card, orderInfo, scriptId);
    } else if (card && invoice) {
      submitted = await paymentService.payInvoice(invoice.id, card);
    } else {
      setMessage('Card is currently unavailable. Please try again.');
    }
    if (!submitted) {
      setMessage('An unexpected error occurred saving your subscription.');
    } else if (nextStep) {
      nextStep(4);
    } else {
      window.location.reload();
    }
    setIsLoading(false);
  };

  const handleNewCardClick = () => {
    setNewCard(true);
  };

  return (
    <>
      <div className="fullWidthContainer">
        <span className="ThreePortionFlex bold">Use an existing card:</span>
        <select id="card-select" className="TwoPortionFlex manualSelect">
          {user.cards.map((card) => (
            <option key={card.id} value={card.id}>
              {card.brand} ending in {card.last4}
            </option>
          ))}
        </select>
      </div>
      <div className="fullWidthContainer" style={{ paddingTop: '40px' }}>
        {!isLoading ? (
          <button
            className="TwoPortionFlex buttonCloneLight"
            style={{ margin: '0px' }}
            onClick={handleNewCardClick}
          >
            <span id="button-text">New Card</span>
          </button>
        ) : (
          <span className="TwoPortionFlex"></span>
        )}
        <span className="OnePortionFlex"></span>
        <button
          className="TwoPortionFlex buttonClone"
          disabled={isLoading}
          id="finalize_payment_button_2"
          onClick={handleSubmit}
          style={{ margin: '0px' }}
        >
          <span id="button-text">{isLoading ? <LoadingSpinner /> : 'Pay now'}</span>
        </button>
      </div>
      {message && (
        <div id="payment-message fullWidthContainer padded errorText">
          <br />
          {message}
        </div>
      )}
    </>
  );
};

export default CheckoutForm;
