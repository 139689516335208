// Import Libraries
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

// Services
import userService from '../../services/userService';
import dictionaryService from '../../services/dictionaryService';

// Components
import SelectBox from '../../components/Elements/Select';

const AboutUs = ({ userDetails }) => {
  const [error, setError] = useState(null);
  const [showSaved, setShowSaved] = useState(false);
  const [aboutUsDict, setAboutUsDict] = useState([]);
  const [userData, setUserData] = useState({
    about_us: '',
    about_us_text: '',
  });

  const updateUser = async () => {
    try {
      let data = userData;
      data.id = userDetails.id;
      let userUpdate = await userService.updateProfileFields(data);
      if (userUpdate) {
        setShowSaved(true);
        setTimeout(() => {
          setShowSaved(false);
        }, 2000);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const updateAboutUs = async (value) => {
    setUserData({ ...userData, about_us: value });
  };

  useEffect(() => {
    const fetchData = async () => {
      let usersStoredData = await userService.getUserAboutUs(userDetails.id);
      if (usersStoredData) {
        setUserData({
          about_us: usersStoredData.about_us || '',
          about_us_text: usersStoredData.about_us_text || '',
        });
      }
      let getAboutUs = await dictionaryService.fetchDictionary('about_us');
      if (getAboutUs) {
        getAboutUs.sort((a, b) => (a.order ? a.order - b.order : 0));
        setAboutUsDict(getAboutUs);
      }
    };

    if (userDetails) {
      fetchData();
    }
  }, [userDetails]);

  return (
    <div className="card" style={{ minWidth: '300px' }}>
      <div style={{ padding: '10px' }}>
        <div className="cardTitle fullWidthContainer">
          <span className="OnePortionFlex">How did you hear about us?</span>
          <span className="OnePortionFlex"></span>
          {!showSaved && (
            <button className="OnePortionFlex buttonClone" onClick={() => updateUser()}>
              Save
            </button>
          )}
          {showSaved && <FontAwesomeIcon className="greenText large" icon={faCheck} />}
        </div>
        <div style={{ width: '100%' }}>
          <SelectBox
            selectLabel={``}
            labelStyle={`none`}
            selectOptions={aboutUsDict.map((item) => item.dict_about_us)}
            selectedValue={userData.about_us}
            handleSelectChange={updateAboutUs}
          />
        </div>
        <div className="fullWidthFlex">
          <textarea
            className="fullWidthContainer addBottomSpacing"
            placeholder="Can you give more specifics, like which social media platform or what exactly you searched for on google? This helps us improve how we spread the word about our service."
            value={userData.about_us_text}
            onChange={(e) => {
              setUserData({ ...userData, about_us_text: e.target.value });
            }}
          />
        </div>

        <div className="fullWidthContainer">
          <p className="errorText">{error}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
