const axios = require('axios');

function paramsOLD(method, body) {
  let header = {};
  if (body) {
    header = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    };
    body = JSON.stringify(body);
    return { method: method, headers: header, body: body };
  } else {
    header = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return { method: method, headers: header };
  }
}

function params(method, body) {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token available');
  }

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(body && { 'Content-Type': 'application/json' }),
  };

  return body ? { method, headers, body: JSON.stringify(body) } : { method, headers };
}

const paymentService = {
  /**
   * Creates a setup intent for a given user to set up a payment method.
   * @param {string} userId - REQUIRED: The ID of the user for whom the setup intent is created.
   * @returns {Object|null} The setup intent object if successful, or null if an error occurs.
   * @throws {Error} If an error occurs during the fetch operation.
   */
  createSetupIntent: async function (userId) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/payments/createSetupIntent`;
      let requestBody = {
        userId: userId,
      };
      const response = await fetch(url, params('POST', requestBody));

      if (response.ok) {
        const intent = await response.json();
        return intent;
      }
      return null;
    } catch (error) {
      return null;
    }
  },

  payInvoice: async function (invoiceId, payMethod) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/payments/payInvoice`;
      let requestBody = {
        invoiceId: invoiceId,
        payMethod: payMethod,
      };
      const response = await fetch(url, params('POST', requestBody));

      if (response.ok) {
        const success = await response.json();
        return success;
      }
      return null;
    } catch (error) {
      return null;
    }
  },

  /**
   * Retrieves all available payment cards for a given user from Stripe.
   * @param {string} userId - REQUIRED: The ID of the user for whom available cards are to be retrieved.
   * @returns {Object[]|null} An array containing available payment cards for the user if successful, or null if the user ID is invalid or an error occurs.
   * @throws {Error} If an error occurs during the fetch operation.
   */
  getAvailableCards: async function (userId) {
    try {
      if (!userId) return null;
      let url = `${process.env.REACT_APP_API_URL}/payments/availableCards/${userId}`;
      const response = await fetch(url, params('GET'));

      if (response.ok) {
        const cards = await response.json();
        return cards;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  checkCouponCode: async function (couponCode) {
    try {
      if (!couponCode) return { error: 'No coupon code provided' };
      let url = `${process.env.REACT_APP_API_URL}/payments/verifyCouponCode/${couponCode}`;
      const response = await fetch(url, params('GET'));

      const code = await response.json();
      return code;
    } catch (error) {
      console.error(error);
      return { error: 'Could not process coupon code' };
    }
  },

  checkForPaymentsDue: async function (userId) {
    try {
      if (!userId) return null;
      let url = `${process.env.REACT_APP_API_URL}/payments/checkForPaymentsDue/${userId}`;
      const response = await fetch(url, params('GET'));

      if (response.ok) {
        const due = await response.json();
        return due;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  /*
  chargeExistingCard: async function (user, planId, card) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/payments/chargeExistingCard`;
      let requestBody = {
        userId: user.id,
        planId: planId,
        cardId: card,
      };
      const response = await fetch(url, params('POST', requestBody));

      if (response.ok) {
        const charge = await response.json();
        return charge;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  */
};

export default paymentService;
